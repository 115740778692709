import {useState} from 'react';
import {useMutation, useQuery, gql} from '@apollo/client';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {useAuth} from './AuthContext';
import ButtonsAddDefine from './ButtonsAddDefine';
import DialogAdd from './DialogAdd';
import DialogDefine from './DialogDefine';
import DialogOkCancel from './DialogOkCancel';
import LoadingLine from './LoadingLine';
import {ignoreCaseComparator} from '../utils/comparator';
import {postSlack} from '../utils/slack';

const QUERY = gql`
  query Query($id: ID!) {
    getRobot(id: $id) {
      id
      serial_number
      software {
        id
        description
        version
      }
    }
    listSoftware {
      id
      description
    }
  }
`;

const QUERYVERSION = gql`
  query Query($id: ID!) {
    listVersionPerSoftware(id: $id)
  }
`;

const DELETE = gql`
  mutation Mutation($operator: String!, $id: ID!, $software: ID!) {
    removeSoftware(operator: $operator, id: $id, software: $software) {
      id
      serial_number
      software {
        id
        description
        version
      }
      history {
        id
        state
        name
        location
        log
        created_at
      }
    }
  }
`;

const ADD = gql`
  mutation Mutation($operator: String!, $id: ID!, $software: ID!, $version: String) {
    addSoftware(operator: $operator, id: $id, software: $software, version: $version) {
      id
      serial_number
      software {
        id
        description
        version
      }
      history {
        id
        state
        name
        location
        log
        created_at
      }
    }
  }
`;

const DEFINE = gql`
  mutation Mutation($operator: String!, $description: String!) {
    defineSoftware(operator: $operator, description: $description) {
      id
      description
    }
  }
`;

const makeMessage = (robot, message, list, info) => {
  const target = `${robot?.history?.[0].name ?? ''} / ${robot?.serial_number}`;
  const description = list?.find(i => i.id === info.id)?.description ?? '';
  const contents = `${message}: ${description}: ${info.version}`;
  return `${target}: ${contents}`;
};

const RobotDetailSoftware = ({id}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDefine, setOpenDefine] = useState(false);
  const [mutationInfo, setMutationInfo] = useState(null);
  const {getEmail} = useAuth();
  const {loading, error, data} = useQuery(QUERY, {
    variables: {id: id}
  });
  const [deleteSoftware] = useMutation(DELETE, {
    onCompleted: ({removeSoftware: robot}) => {
      postSlack(getEmail(),
        makeMessage(
          robot,
          'remove software',
          data.listSoftware,
          mutationInfo
        )
      );
    }
  });
  const [addSoftware] = useMutation(ADD, {
    onCompleted: ({addSoftware: robot}) => {
      postSlack(getEmail(),
        makeMessage(
          robot,
          'add software',
          data.listSoftware,
          mutationInfo
        )
      );
    }
  });
  const [defineSoftware] = useMutation(DEFINE, {
    refetchQueries: ['Query'],
    onCompleted: ({defineSoftware: software}) => {
      postSlack(getEmail(), `define software: ${software?.description}`);
    }
  });
  if (loading) return <LoadingLine />;
  if (error) return <p>Error :(</p>;
  const onDeleteQuery = softwareId => {
    setMutationInfo({id: softwareId, version: ''});
    setOpenDelete(true);
  };
  const onDeleteOk = () => {
    deleteSoftware({
      variables: {
        operator: getEmail(),
        id: id,
        software: mutationInfo.id
      }
    });
    setOpenDelete(false);
  };
  const onAdd = (softwareId, version) => {
    setMutationInfo({id: softwareId, version: version});
    setTimeout(() => {
      addSoftware({
        variables: {
          operator: getEmail(),
          id: id,
          software: softwareId,
          version: version
        }
      });
    }, 0);
    setOpenAdd(false);
  };
  const onDefine = softwareName => {
    defineSoftware({variables: {
      operator: getEmail(),
      description: softwareName
    }});
    setOpenDefine(false);
  };
  const contents = data.getRobot.software?.map(i =>
    <TableRow key={i.id}>
      <TableCell>{i.description}</TableCell>
      <TableCell>{i.version}</TableCell>
      <TableCell>
        <Button onClick={e => onDeleteQuery(i.id)}>Delete</Button>
      </TableCell>
    </TableRow>
  );
  const filteredSoftware = data.listSoftware?.filter(i =>
    !data.getRobot.software?.find(j => j.id === i.id)
  );
  filteredSoftware.sort((a, b) =>
    ignoreCaseComparator(a.description, b.description)
  );
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Version</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contents}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonsAddDefine
        text="software"
        onAdd={() => setOpenAdd(true)}
        onDefine={() => setOpenDefine(true)}
      />
      <DialogAdd
        label1="Software"
        label2="Version"
        open={openAdd}
        items={filteredSoftware}
        onCancel={() => setOpenAdd(false)}
        onOk={onAdd}
        queryDetails={QUERYVERSION}
        queryName="listVersionPerSoftware"
      />
      <DialogDefine
        open={openDefine}
        text="software"
        label="Software"
        onCancel={() => setOpenDefine(false)}
        onOk={onDefine}
      />
      <DialogOkCancel
        open={openDelete}
        title="Delete"
        content="Are you sure?"
        onCancel={() => setOpenDelete(false)}
        onOk={onDeleteOk}
      />
    </>
  );
};

export default RobotDetailSoftware;

// vim: set expandtab shiftwidth=2:
