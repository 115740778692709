import {useEffect, useState} from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import SlideDialog from './SlideDialog';
import stateDecoder from '../utils/stateDecoder';

const DialogState = ({
  open,
  onCancel, onOk,
  state,
}) => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    if (open)
      setValue(state);
  }, [open, state]);
  const menues = [...Array(10).keys()].map(i =>
    <MenuItem key={i} value={i}>{stateDecoder(i)}</MenuItem>
  );
  return (
    <SlideDialog open={open} onClose={onCancel}>
      <DialogTitle>Update state</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To update state, Please select state here.
        </DialogContentText>
        <FormControl margin="normal">
          <InputLabel>State</InputLabel>
          <Select
            label="Select"
            sx={{width:200}}
            value={value}
            onChange={e => setValue(e.target.value)}>
            {menues}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onOk(value)}>Update</Button>
      </DialogActions>
    </SlideDialog>
  );
};

export default DialogState;
