const isLocalMode = () => {
  return process.env.REACT_APP_BACKEND_API_URL === 'http://localhost:4000/graphql';
}

export const getSlackUrl = () => {
  // #div-07_karte_progress
  const prodSlackWebhookUrl = 'https://hooks.slack.com/services/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';
  // #div-07_karte_progress_dev
  const localSlackWebhookUrl = 'https://hooks.slack.com/services/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';
  return isLocalMode() ? localSlackWebhookUrl : prodSlackWebhookUrl;
}

export const getRedirectUrl = () => {
  return isLocalMode() ? 'http://localhost:3000/' : 'https://robotkarte.com/';
}

export const getEnvironment = () => {
  return isLocalMode() ? 'local' : 'production';
}
