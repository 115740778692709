import {getEnvironment, getSlackUrl} from './localMode';
import axios from 'axios';

export const postSlack = (operator, text) => {
  const data = {
    text: `[${getEnvironment()}] ${operator}\n${text}`
  };
  axios.post(getSlackUrl(), JSON.stringify(data), {
    withCredentials: false,
    transformRequest: [(data, headers) => {
      delete headers.post['Content-Type'];
      return data;
    }]
  }).then(res => {
    console.log('slack response: ', res);
  });
};
