import {useEffect, useState} from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import SlideDialog from './SlideDialog';

const DialogConstruct = ({open, onCancel, onOk}) => {
  const [count, setCount] = useState(0);
  const [startName, setStartName] = useState('');
  useEffect(() => {
    if (open) {
      setCount(1);
      setStartName('');
    }
  }, [open]);
  const re = /^[cv]\d{2}[a-z]{2}\d{3}$/i;
  const error = startName.length !== 0 && !startName.match(re);
  return (
    <SlideDialog open={open} onClose={onCancel}>
      <DialogTitle>Construct robot</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To construct robot, Please click Construct.
        </DialogContentText>
        <FormControl margin="normal">
          <InputLabel>Construct count</InputLabel>
          <Select
            label="Construct count"
            value={count}
            onChange={e => setCount(Number(e.target.value))}
          >
            {
              [...Array(50).keys()].map(i =>
                <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
              )
            }
          </Select>
          <TextField
            fullWidth
            margin="normal"
            label="Start Name"
            placeholder="v63mpXXX"
            value={startName}
            onChange={e => setStartName(e.target.value)}
            error={error}
            helperText={error ? 'Invalid name' : ''}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button disabled={error} onClick={() => onOk(count, startName)}>Construct</Button>
      </DialogActions>
    </SlideDialog>
  );
};

export default DialogConstruct;
