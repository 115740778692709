import {useState} from 'react';
import {useMutation, useQuery, gql} from '@apollo/client';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {useAuth} from './AuthContext';
import ButtonsAddDefine from './ButtonsAddDefine';
import DialogDefine from './DialogDefine';
import DialogOkCancel from './DialogOkCancel';
import DialogAdd from './DialogAdd';
import LoadingLine from './LoadingLine';
import {ignoreCaseComparator} from '../utils/comparator';
import {postSlack} from '../utils/slack';

const QUERY = gql`
  query Query($id: ID!) {
    getRobot(id: $id) {
      id
      tag {
        id
        description
        value
      }
    }
    listTags {
      id
      description
    }
  }
`;

const QUERYTAG = gql`
  query Query($id: ID!) {
    listValuePerTag(id: $id)
  }
`;

const ADD = gql`
  mutation Mutation($operator: String!, $id: ID!, $tag: ID!, $value: String) {
    addTag(operator: $operator, id: $id, tag: $tag, value: $value) {
      id
      serial_number
      tag {
        id
        description
        value
      }
      history {
        id
        state
        name
        location
        log
        created_at
      }
    }
  }
`;

const DELETE = gql`
  mutation Mutation($operator: String!, $id: ID!, $tag: ID!) {
    removeTag(operator: $operator, id: $id, tag: $tag) {
      id
      serial_number
      tag {
        id
        description
        value
      }
      history {
        id
        state
        name
        location
        log
        created_at
      }
    }
  }
`;

const DEFINE = gql`
  mutation Mutation($operator: String!, $description: String!) {
    defineTag(operator: $operator, description: $description) {
      id
      description
    }
  }
`;

const makeMessage = (robot, message, list, info) => {
  const target = `${robot?.history?.[0].name ?? ''} / ${robot?.serial_number}`;
  const description = list?.find(i => i.id === info.id)?.description ?? '';
  const contents = `${message}: ${description}: ${info.value}`;
  return `${target}: ${contents}`;
};

const RobotDetailTag = ({id}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDefine, setOpenDefine] = useState(false);
  const [mutationInfo, setMutationInfo] = useState(null);
  const {getEmail} = useAuth();
  const {loading, error, data} = useQuery(QUERY, {
    variables: {id: id}
  });
  const [deleteTag] = useMutation(DELETE, {
    onCompleted: ({removeTag: robot}) => {
      postSlack(getEmail(),
        makeMessage(
          robot,
          'remove tag',
          data.listTags,
          mutationInfo
        )
      );
    }
  });
  const [addTag] = useMutation(ADD, {
    onCompleted: ({addTag: robot}) => {
      postSlack(getEmail(),
        makeMessage(
          robot,
          'add tag',
          data.listTags,
          mutationInfo
        )
      );
    }
  });
  const [defineTag] = useMutation(DEFINE, {
    refetchQueries: ['Query'],
    onCompleted: ({defineTag: tag}) => {
      postSlack(getEmail(), `define tag: ${tag?.description}`);
    }
  });
  if (loading) return <LoadingLine />;
  if (error) return <p>Error :(</p>;
  const onDeleteQuery = tagId => {
    setMutationInfo({id: tagId, value: ''});
    setOpenDelete(true);
  };
  const onDeleteOk = () => {
    deleteTag({
      variables: {
        operator: getEmail(),
        id: id,
        tag: mutationInfo.id
      }
    });
    setOpenDelete(false);
  };
  const onAdd = (tagId, value) => {
    setMutationInfo({id: tagId, value: value});
    setTimeout(() => {
      addTag({
        variables: {operator: getEmail(), id: id, tag: tagId, value: value}
      });
    }, 0);
    setOpenAdd(false);
  };
  const onDefine = tagName => {
    defineTag({variables: {operator: getEmail(), description: tagName}});
    setOpenDefine(false);
  };
  const contents = data.getRobot.tag?.map(i =>
    <TableRow key={i.id}>
      <TableCell>{i.description}</TableCell>
      <TableCell>{i.value}</TableCell>
      <TableCell>
        <Button onClick={e => onDeleteQuery(i.id)}>
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
  const filteredTag = data.listTags?.filter(i =>
    !data.getRobot.tag?.find(j => j.id === i.id)
  );
  filteredTag.sort((a, b) =>
    ignoreCaseComparator(a.description, b.description)
  );
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Value</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contents}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonsAddDefine
        text="tag"
        onAdd={() => setOpenAdd(true)}
        onDefine={() => setOpenDefine(true)}
      />
      <DialogAdd
        label1="Tag"
        label2="Value"
        open={openAdd}
        items={filteredTag}
        onCancel={() => setOpenAdd(false)}
        onOk={onAdd}
        allowNoInput
        queryDetails={QUERYTAG}
        queryName="listValuePerTag"
      />
      <DialogDefine
        open={openDefine}
        text="tag"
        label="Tag"
        onCancel={() => setOpenDefine(false)}
        onOk={onDefine}
      />
      <DialogOkCancel
        open={openDelete}
        title="Delete"
        content="Are you sure?"
        onCancel={() => setOpenDelete(false)}
        onOk={onDeleteOk}
      />
    </>
  );
};

export default RobotDetailTag;

// vim: set expandtab shiftwidth=2:
