import {useEffect, useState} from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SlideDialog from './SlideDialog';
import {categoryDecoder} from '../utils/categoryDecoder';

const DialogLogAdd = ({
  open,
  onCancel, onOk,
  nofile,
}) => {
  const [text, setText] = useState('');
  const [category, setCategory] = useState(1);
  const [files, setFiles] = useState([]);
  useEffect(() => {
    if (open) {
      setText('');
      setCategory(1);
      setFiles([]);
    }
  }, [open]);
  const addFiles = fileList => {
    const filtered = Array.from(fileList).filter(i => !files.find(j => i.name === j.name));
    setFiles([...files, filtered].flat());
  };
  const removeFile = index => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };
  const categories = [...Array(4).keys()].map(i => i + 1).map(i =>
    <MenuItem key={i} value={i}>{categoryDecoder(i)}</MenuItem>
  );
  const textEmpty = !text || text.length === 0;
  const fileContents =
    <List dense>
      {
        files.map((f, i) =>
          <ListItem key={i}>
            <ListItemText primary={f.name} />
            <ListItemSecondaryAction>
              <IconButton color="primary" onClick={() => removeFile(i)}>
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )
      }
      <ListItem>
        <ListItemButton component="label">
          <ListItemText primary="SELECT FILES" primaryTypographyProps={{color: 'primary'}} />
          <input type="file" onChange={e => addFiles(e.target.files)} hidden multiple />
        </ListItemButton>
      </ListItem>
    </List>;
  return (
    <SlideDialog open={open} onClose={onCancel}>
      <DialogTitle>Add log</DialogTitle>
      <DialogContent sx={{width: 400}}>
        <DialogContentText>
          To add log, Please enter here.
        </DialogContentText>
        <TextField
          fullWidth
          multiline
          margin="normal"
          label="Log"
          rows={6}
          value={text}
          onChange={e => setText(e.target.value)}
          placeholder="Log"
          error={textEmpty}
          helperText={textEmpty ? 'Empty' : ''}
        />
        <FormControl margin="normal" fullWidth>
          <InputLabel>Category</InputLabel>
          <Select
            label="Category"
            value={category}
            onChange={e => setCategory(e.target.value)}
          >
            {categories}
          </Select>
        </FormControl>
        {
          nofile ||
          <>
            <DialogContentText sx={{mt: 1}}>Add files (optional)</DialogContentText>
            {fileContents}
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button disabled={textEmpty} onClick={() => onOk({log: text, category: category, files: files})}>Add</Button>
      </DialogActions>
    </SlideDialog>
  );
};

export default DialogLogAdd;
