import {useState} from 'react';
import {useMutation, gql} from '@apollo/client';
import {
  Button,
  Grid,
  Typography
} from '@mui/material';
import {useAuth} from './AuthContext';
import DialogState from './DialogState';
import {postSlack} from '../utils/slack';
import stateDecoder from '../utils/stateDecoder';

const SET = gql`
  mutation Mutation($operator: String!, $id: ID!, $state: Int!) {
    setState(operator: $operator, id: $id, state: $state) {
      id
      serial_number
      history {
        id
        state
        name
        location
        log
        created_at
      }
    }
  }
`;

const RobotDetailHeaderState = ({robot}) => {
  const [open, setOpen] = useState(false);
  const {getEmail} = useAuth();
  const [setState] = useMutation(SET, {
    onCompleted: ({setState: robot}) => {
      const history = robot?.history?.[0];
      const target = `${history?.name ?? ''} / ${robot?.serial_number}`;
      postSlack(getEmail(), `${target}: state changed to ${stateDecoder(history?.state)}`);
    }
  });
  const onUpdate = value => {
    setState({variables: {operator: getEmail(), id: robot.id, state: Number(value)}});
    setOpen(false);
  };
  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={2}>
          <Typography variant="body1" align="right">State:</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body1">
            {stateDecoder(robot.history?.[0].state)}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Button onClick={() => setOpen(true)}>Update</Button>
        </Grid>
      </Grid>
      <DialogState
        open={open}
        onCancel={() => setOpen(false)}
        onOk={onUpdate}
        state={robot.history?.[0].state ?? 9}
      />
    </>
  );
};

export default RobotDetailHeaderState;

// vim: set expandtab shiftwidth=2:
