import {
  Box,
  LinearProgress,
} from '@mui/material';

const LoadingLine = () => <Box sx={{width: '100%'}}><LinearProgress /></Box>;

export default LoadingLine;

// vim: set expandtab shiftwidth=2:
