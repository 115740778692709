import {
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SearchIcon from '@mui/icons-material/Search';
import {SearchFilterCategory} from '../utils/filter'


const filterCategories = () => {
  let options = [];
  Object.keys(SearchFilterCategory).forEach((categoryKey, i) => {
    let category = SearchFilterCategory[categoryKey];
    let displayName = i === 0 ? <em>{categoryKey}</em> : categoryKey;
    options.push(<MenuItem key={i} value={category}>{displayName}</MenuItem>);
  });

  return options;
}

const makeSortButton = (sortModel, onSortModelChange, field, label) => {
  const onClick = field => {
    onSortModelChange({
      field: field,
      order: sortModel?.field !== field ? 'asc'
           : sortModel?.order === 'asc' ? 'desc'
                                        : 'asc'
    });
  };
  if (sortModel?.field === field) {
    const order = sortModel?.order;
    if (order) {
      if (order === 'asc')
        return (
          <Button
            variant="contained"
            endIcon={<ArrowUpwardIcon />}
            onClick={() => onClick(field)}
          >
            {label}
          </Button>
        );
      else if (order === 'desc')
        return (
          <Button
            variant="contained"
            endIcon={<ArrowDownwardIcon />}
            onClick={() => onClick(field)}
          >
            {label}
          </Button>
        );
    }
  }
  return <Button onClick={() => onClick(field)}>{label}</Button>;
};

const RobotCardsBar = ({
  sortModel, onSortModelChange,
  searchKeyword, onSearchKeywordChange,
  searchCategory, onSearchCategoryChange,
  multiMode, onChangeMultiMode, editDisabled,
  onEditMulti, timelineMode,
}) =>
  <Stack direction="row" alignItems="center" sx={{width: '100%', justifyContent: 'space-between'}}>
    <Stack direction="row" alignItems="center">
      <Typography>Multi:</Typography>
      <Switch
        checked={multiMode}
        onChange={e => onChangeMultiMode(e.target.checked)}
      />
      <Button disabled={editDisabled} onClick={onEditMulti}>Edit</Button>
    </Stack>
    <Stack direction="row" alignItems="center">
      <ButtonGroup variant="outlined">
        {makeSortButton(sortModel, onSortModelChange, 'name', 'Name')}
        {makeSortButton(sortModel, onSortModelChange, 'status', 'Status')}
        {makeSortButton(sortModel, onSortModelChange, 'location', 'Location')}
        {!timelineMode && makeSortButton(sortModel, onSortModelChange, 'datetime', 'Log')}
      </ButtonGroup>
      <Paper
        component="form"
        sx={{
          ml: 2,
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: 500
        }}
      >
        <IconButton type="button" sx={{pointerEvents: 'none', p: '10px'}}>
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{flex: 1}}
          placeholder="Search"
          value={searchKeyword}
          onChange={e => onSearchKeywordChange(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter')
              e.preventDefault();
          }}
        />
        <Divider orientation="vertical" flexItem/>
        <FormControl
          sx={{ ml: 2, minWidth: 120}}
          variant="standard"
        >
          <Select
            disableUnderline
            value={searchCategory}
            onChange={e => onSearchCategoryChange(e.target.value)}
          >
            {filterCategories()}
          </Select>
        </FormControl>
      </Paper>
    </Stack>
  </Stack>;

export default RobotCardsBar;
