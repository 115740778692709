import {ignoreCaseComparator} from './comparator';
import stateDecoder from './stateDecoder';

export const SearchFilterCategory = {
  All: "ALL",
  Name: "NAME",
  Log: "LOG",
  Component: "COMP",
  Software: "SOFT",
  Tag: "TAG"
}

const productFilter = (filter, name) =>
  !filter ||
  filter.length === 0 ||
  (name?.[0] === 'V' && filter.includes('Hybrid-AMR')) ||
  (name?.startsWith('V4') && filter.includes('Hybrid-AMR V4')) ||
  (name?.startsWith('V5') && filter.includes('Hybrid-AMR V5')) ||
  (name?.startsWith('V6') && filter.includes('Hybrid-AMR V6')) ||
  (name?.[0] === 'C' && filter.includes('Charger'));

const filter = (filter, item) =>
  !filter || filter.length === 0 || filter.includes(item);

const tagFilter = (filter, tags) =>
  !filter ||
  filter.length === 0 ||
  tags.find(i => filter.includes(i.description));

const searchFilter = (keyword, category, device) => {
  if (!keyword || !device.history)
    return true;
  
  const currentHistory = device.history[0];

  switch(category) {
    case SearchFilterCategory.All:
      return isKeywordInProperty(keyword, [currentHistory.name]) ||
        isKeywordInList(keyword, device.history, ["log"]) ||
        isKeywordInList(keyword, device.component, ["description", "serial_number"]) ||
        isKeywordInList(keyword, device.software, ["description", "version"]) ||
        isKeywordInList(keyword, device.tag, ["description"]);
    case SearchFilterCategory.Name:
      return isKeywordInProperty(keyword, [currentHistory.name]);
    case SearchFilterCategory.Log:
      return isKeywordInList(keyword, device.history, ["log"]);
    case SearchFilterCategory.Component:
      return isKeywordInList(keyword, device.component, ["description", "serial_number"]);
    case SearchFilterCategory.Software:
      return isKeywordInList(keyword, device.software, ["description", "version"]);
    case SearchFilterCategory.Tag:
      return isKeywordInList(keyword, device.tag, ["description"]);
    default:
      return false;
  }
};

const isKeywordInList = (keyword, list, properties) => {
  for(let item of list) {
    let props = properties.map((val) => { return item[val]; });
    let match = isKeywordInProperty(keyword, props);
    if(match) {
      return true;
    }
  }
  return false;
}

const isKeywordInProperty = (keyword, properties) => {
  if(properties.length === 0) {
    return false;
  }

  let lowerKeyword = keyword?.toLowerCase();
  let match = false;

  properties.forEach((property, i) => {
    let lowerProperty = property?.toLowerCase();
    if(i === 0) {
      match = lowerProperty?.includes(lowerKeyword) ?? false;
    } else {
      match = match || (lowerProperty?.includes(lowerKeyword) ?? false);
    }
  });

  return match;
}

export const filterRobot = (robots, filterModel, searchKeyword, searchCategory) => {
  return robots.filter(i =>
    productFilter(filterModel['Product'], i.history?.[0].name?.toUpperCase()) &&
    filter(filterModel['State'], stateDecoder(i.history?.[0].state)) &&
    filter(filterModel['Operator'], i.history?.[0].operator_id) &&
    filter(filterModel['Location'], i.history?.[0].location) &&
    tagFilter(filterModel['Tag'], i.tag) &&
    searchFilter(searchKeyword, searchCategory, i)
  );
}

export const getStatistics = robots => {
  const states = {};
  const operatorSet = new Set();
  const locationSet = new Set();
  const tagSet = new Set();
  robots.forEach(i => {
    const state = i.history?.[0].state;
    states[state] ??= 0;
    ++states[state];
    operatorSet.add(i.history?.[0].operator_id);
    locationSet.add(i.history?.[0].location);
    i.tag?.forEach(i => tagSet.add(i.description));
  });
  const operators = Array.from(operatorSet.values()).sort(ignoreCaseComparator).filter(i => !!i);
  const locations = Array.from(locationSet.values()).sort(ignoreCaseComparator).filter(i => !!i);
  const tags = Array.from(tagSet.values()).sort(ignoreCaseComparator).filter(i => !!i);
  return [states, operators, locations, tags];
};

export const getIndividualStates = robots => {
  const states = {robot: {}, charger: {}};
  robots.forEach(i => {
    const name = i.history?.[0].name?.toUpperCase();
    const device = name?.[0] === 'V' ? states.robot
                 : name?.[0] === 'C' ? states.charger
                                     : undefined;
    if (device) {
      const state = i.history?.[0].state;
      device[state] ??= 0;
      ++device[state];
    }
  });
  return states;
};
