import {ButtonBase} from '@mui/material';
import {categoryDecoderShort} from '../utils/categoryDecoder';
import {categoryColor} from '../utils/color';

const LogCategory = ({disabled, category, onClick}) => {
  return !category || category === 0
    ? ''
    : <ButtonBase disabled={disabled} onClick={onClick} sx={{
        background: categoryColor(category),
        borderRadius: '3px',
        padding: '6px',
        textAlign: 'center',
        boxShadow: 4,
        fontSize: 10,
        fontWeight: 'bold',
        width: '100%'
      }}>
        {categoryDecoderShort(category)}
      </ButtonBase>;
};

export default LogCategory;
