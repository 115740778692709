import {useState} from 'react';
import {useQuery, useMutation, gql} from '@apollo/client';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {useAuth} from './AuthContext';
import DialogAdd from './DialogAdd';
import DialogDefine from './DialogDefine';
import DialogLocation from './DialogLocation';
import DialogLogAdd from './DialogLogAdd';
import DialogState from './DialogState';
import LoadingLine from './LoadingLine';
import {categoryDecoder} from '../utils/categoryDecoder';
import {getLocations} from '../utils/locations';
import {postSlack} from '../utils/slack';
import stateDecoder from '../utils/stateDecoder';
import toJSTString from '../utils/toJSTString';

const QUERY = gql`
  query Query($ids: [ID!]!) {
    getAllRobots {
      id
      history {
        location
      }
    }
    getListRobots(ids: $ids) {
      id
      serial_number
      tag {
        id
        description
      }
      history {
        created_at
        operator_id
        id
        state
        location
        log
        name
      }
      component {
        id
        description
        serial_number
      }
      software {
        id
        description
        version
      }
    }
    listComponents {
      id
      description
    }
    listSoftware {
      id
      description
    }
    listTags {
      id
      description
    }
  }
`;

const QUERYVERSION = gql`
  query Query($id: ID!) {
    listVersionPerSoftware(id: $id)
  }
`;

const QUERYSERIAL = gql`
  query Query($id: ID!) {
    listSerialPerComponent(id: $id)
  }
`;

const QUERYTAG = gql`
  query Query($id: ID!) {
    listValuePerTag(id: $id)
  }
`;

const SETSTATE = gql`
  mutation SetStateMultiple($operator: String!, $ids: [ID!]!, $state: Int!) {
    setStateMultiple(operator: $operator, ids: $ids, state: $state) {
      id
      serial_number
      tag {
          id
          description
      }
      history {
          created_at
          operator_id
          id
          state
          location
          log
          name
      }
      component {
          id
          description
          serial_number
      }
      software {
          id
          description
          version
      }
    }
  }
`;

const SETLOCATION = gql`
  mutation SetLocationMultiple($operator: String!, $ids: [ID!]!, $location: String!) {
    setLocationMultiple(operator: $operator, ids: $ids, location: $location) {
      id
      serial_number
      tag {
        id
        description
      }
      history {
        created_at
        operator_id
        id
        state
        location
        log
        name
      }
      component {
        id
        description
        serial_number
      }
      software {
        id
        description
        version
      }
    }
  }
`;

const SETLOG = gql`
  mutation SetLogMultiple($operator: String!, $ids: [ID!]!, $log: String!, $category: Int!) {
    setLogMultiple(operator: $operator, ids: $ids, log: $log, category: $category) {
      id
      serial_number
      tag {
        id
        description
      }
      history {
        created_at
        operator_id
        id
        state
        location
        log
        name
      }
      component {
        id
        description
        serial_number
      }
      software {
        id
        description
        version
      }
    }
  }
`;

const ADDTAG = gql`
  mutation AddTagMultiple($operator: String!, $ids: [ID!]!, $tag: ID!, $value: String) {
    addTagMultiple(operator: $operator, ids: $ids, tag: $tag, value: $value) {
      id
      serial_number
      tag {
        id
        description
      }
      history {
        created_at
        operator_id
        id
        state
        location
        log
        name
      }
      component {
        id
        description
        serial_number
      }
      software {
        id
        description
        version
      }
    }
  }
`;

const ADDSOFTWARE = gql`
  mutation AddSoftwareMultiple($operator: String!, $ids: [ID!]!, $software: ID!, $version: String) {
    addSoftwareMultiple(operator: $operator, ids: $ids, software: $software, version: $version) {
      id
      serial_number
      tag {
        id
        description
      }
      history {
        created_at
        operator_id
        id
        state
        location
        log
        name
      }
      component {
        id
        description
        serial_number
      }
      software {
        id
        description
        version
      }
    }
  }
`;

const ADDCOMPONENT = gql `
  mutation AddComponentMultiple($operator: String!, $ids: [ID!]!, $component: ID!, $serial: String!) {
    addComponentMultiple(operator: $operator, ids: $ids, component: $component, serial: $serial) {
      id
      serial_number
      tag {
        id
        description
      }
      history {
        created_at
        operator_id
        id
        state
        location
        log
        name
      }
      component {
        id
        description
        serial_number
      }
      software {
        id
        description
        version
      }
    }
  }
`;

const DEFINETAG = gql`
  mutation DefineTag($operator: String!, $description: String!) {
    defineTag(operator: $operator, description: $description) {
      id
      description
    }
  }
`;

const DEFINESOFTWARE = gql`
  mutation DefineSofware($operator: String!, $description: String!) {
    defineSoftware(operator: $operator, description: $description) {
      id
      description
    }
  }
`;

const DEFINECOMPONENT = gql`
  mutation DefineComponent($operator: String!, $description: String!) {
    defineComponent(operator: $operator, description: $description) {
      id
      description
    }
  }
`;

const makeMessage = (message, robots) => {
  const contents = robots?.map(i =>
    `${i.history?.[0].name ?? ''} / ${i.serial_number}`
  ).join('\n');
  return `${message}\n${contents}`;
};

const RobotMultiple = ({ids, onClick}) => {
  // For state
  const [openState, setOpenState] = useState(false);
  // For location
  const [openLocation, setOpenLocation] = useState(false);
  // For log
  const [openLog, setOpenLog] = useState(false);
  // For Tag
  const [openDefineTag, setOpenDefineTag] = useState(false);
  const [openAddTag, setOpenAddTag] = useState(false);
  // For Component
  const [openDefineComponent, setOpenDefineComponent] = useState(false);
  const [openAddComponent, setOpenAddComponent] = useState(false);
  // For Software
  const [openDefineSoftware, setOpenDefineSoftware] = useState(false);
  const [openAddSoftware, setOpenAddSoftware] = useState(false);

  const [mutationInfo, setMutationInfo] = useState(null);

  const {getEmail} = useAuth();

  const {loading, error, data} = useQuery(QUERY, {
    variables: {ids: ids}
  });

  const [setState] = useMutation(SETSTATE, {
    onCompleted: () => {
      const state = stateDecoder(mutationInfo.state);
      postSlack(getEmail(),
        makeMessage(`state changed to ${state}`, data.getListRobots)
      );
    }
  });
  const [setLocation] = useMutation(SETLOCATION, {
    onCompleted: () => {
      postSlack(getEmail(),
        makeMessage(`location changed to ${mutationInfo.location}`, data.getListRobots)
      );
    }
  });
  const [setLog] = useMutation(SETLOG, {
    onCompleted: () => {
      const {log, category} = mutationInfo;
      postSlack(getEmail(),
        makeMessage(`log add ${log} (${categoryDecoder(category)})`, data.getListRobots)
      );
    }
  });
  const [addTag] = useMutation(ADDTAG, {
    onCompleted: () => {
      const {id, value} = mutationInfo;
      const description = data.listTags?.find(i => i.id === id)?.description ?? '';
      postSlack(getEmail(),
        makeMessage(`add tag ${description}: ${value}`, data.getListRobots)
      );
    }
  });
  const [addComponent] = useMutation(ADDCOMPONENT, {
    onCompleted: () => {
      const {id, serial} = mutationInfo;
      const description = data.listComponents?.find(i => i.id === id)?.description ?? '';
      postSlack(getEmail(),
        makeMessage(`add component ${description}: ${serial}`, data.getListRobots)
      );
    }
  });
  const [addSoftware] = useMutation(ADDSOFTWARE, {
    onCompleted: () => {
      const {id, version} = mutationInfo;
      const description = data.listSoftware?.find(i => i.id === id)?.description ?? '';
      postSlack(getEmail(),
        makeMessage(`add software ${description}: ${version}`, data.getListRobots)
      );
    }
  });
  const [defineTag] = useMutation(DEFINETAG, {
    refetchQueries: ['Query'],
    onCompleted: ({defineTag: tag}) => {
      postSlack(getEmail(), `define tag: ${tag?.description}`);
    }
  });
  const [defineSoftware] = useMutation(DEFINESOFTWARE, {
    refetchQueries: ['Query'],
    onCompleted: ({defineSoftware: software}) => {
      postSlack(getEmail(), `define software: ${software?.description}`);
    }
  });
  const [defineComponent] = useMutation(DEFINECOMPONENT, {
    refetchQueries: ['Query'],
    onCompleted: ({defineComponent: component}) => {
      postSlack(getEmail(), `define component: ${component?.description}`);
    }
  });

  const onUpdateState = state => {
    setMutationInfo({state: state});
    setTimeout(() => {
      setState({variables: {operator: getEmail(), ids: ids, state: state}});
    }, 0);
    setOpenState(false);
  };

  const onUpdateLocation = location => {
    setMutationInfo({location: location});
    setTimeout(() => {
      setLocation({variables: {operator: getEmail(), ids: ids, location: location}});
    }, 0);
    setOpenLocation(false);
  };

  const onUpdateLog = ({log, category}) => {
    setMutationInfo({log: log, category: category});
    setTimeout(() => {
      setLog({variables: {operator: getEmail(), ids: ids, log: log, category: category}});
    }, 0);
    setOpenLog(false);
  };

  const onAddTag = (tagId, value) => {
    setMutationInfo({id: tagId, value: value});
    setTimeout(() => {
      addTag({variables: {operator: getEmail(), ids: ids, tag: tagId, value: value}});
    }, 0);
    setOpenAddTag(false);
  };
  const onAddSoftware = (softwareId, version) => {
    setMutationInfo({id: softwareId, version: version});
    setTimeout(() => {
      addSoftware({variables: {operator: getEmail(), ids: ids, software: softwareId, version: version}});
    }, 0);
    setOpenAddSoftware(false);
  };
  const onAddComponent = (componentId, serial) => {
    setMutationInfo({id: componentId, serial: serial});
    setTimeout(() => {
      addComponent({variables: {operator: getEmail(), ids: ids, component: componentId, serial: serial}});
    }, 0);
    setOpenAddComponent(false);
  };

  const onDefineTag = tagName => {
    defineTag({variables: {operator: getEmail(), description: tagName}});
    setOpenDefineTag(false);
  };
  const onDefineComponent = componentName => {
    defineComponent({variables: {operator: getEmail(), description: componentName}});
    setOpenDefineComponent(false);
  };
  const onDefineSoftware = softwareName => {
    defineSoftware({variables: {operator: getEmail(), description: softwareName}});
    setOpenDefineSoftware(false);
  };

  if (loading) return <LoadingLine />;
  if (error) return <p>Error :(</p>;
  const contents = data.getListRobots?.map(i =>
    <TableRow key={i.id}>
      <TableCell>{i.history[0]?.name}</TableCell>
      <TableCell>{toJSTString(i.history[0]?.created_at)}</TableCell>
      <TableCell>{i.history[0]?.operator_id}</TableCell>
      <TableCell>{stateDecoder(i.history[0]?.state)}</TableCell>
      <TableCell>{i.history[0]?.location}</TableCell>
      <TableCell>{i.history[0]?.log}</TableCell>
      <TableCell>{i.tag?.map(t => t.description + ' ,')}</TableCell>
      <TableCell>{i.software?.map(t => t.description + ' ,')}</TableCell>
      <TableCell>{i.component?.map(t => t.description + ' ,')}</TableCell>
    </TableRow>
  );
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{mt:1}}>
          <Button size="large" onClick={onClick}>Back to list</Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Robot Name</TableCell>
                  <TableCell>Date time</TableCell>
                  <TableCell>Operator</TableCell>
                  <TableCell>State <Button size="small" onClick={() => setOpenState(true)}>Update</Button></TableCell>
                  <TableCell>Location <Button size="small" onClick={() => setOpenLocation(true)}>Update</Button></TableCell>
                  <TableCell>Log <Button size="small" onClick={() => setOpenLog(true)}>Add</Button></TableCell>
                  <TableCell>Tag <Button size="small" onClick={() => setOpenAddTag(true)}>Add</Button></TableCell>
                  <TableCell>Software <Button size="small" onClick={() => setOpenAddSoftware(true)}>Add</Button></TableCell>
                  <TableCell>Component <Button size="small" onClick={() => setOpenAddComponent(true)}>Add</Button></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contents}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item><Button variant="contained" onClick={() => setOpenDefineTag(true)}>Define Tag</Button></Grid>
        <Grid item><Button variant="contained" onClick={() => setOpenDefineSoftware(true)}>Define Software</Button></Grid>
        <Grid item><Button variant="contained" onClick={() => setOpenDefineComponent(true)}>Define Component</Button></Grid>
      </Grid>

      {/* Dialog for Update state */}

      <DialogState
        open={openState}
        onCancel={() => setOpenState(false)}
        onOk={onUpdateState}
        state={9}
      />

      {/* Dialog for Update location */}

      <DialogLocation
        open={openLocation}
        onCancel={() => setOpenLocation(false)}
        onOk={onUpdateLocation}
        locations={getLocations(data?.getAllRobots ?? [])}
        location={''}
      />

      {/* Dialog for Update log */}

      <DialogLogAdd
        open={openLog}
        onCancel={() => setOpenLog(false)}
        onOk={onUpdateLog}
        nofile
      />

      {/* Dialog for Defining new tag/component/software */}

      <DialogDefine
        open={openDefineTag || openDefineComponent || openDefineSoftware}
        text={openDefineTag ? 'tag' : openDefineComponent ? 'component' : openDefineSoftware ? 'software' : ''}
        label={openDefineTag ? 'Tag' : openDefineComponent ? 'Component' : openDefineSoftware ? 'Software' : ''}
        onCancel={() => openDefineTag ? setOpenDefineTag(false) : openDefineComponent ? setOpenDefineComponent(false) : setOpenDefineSoftware(false)}
        onOk={openDefineTag ? onDefineTag : openDefineComponent ? onDefineComponent : onDefineSoftware}
      />

      {/* Dialogs for Adding Tag/software/component */}

      <DialogAdd
        label1="Tag"
        label2="Value"
        open={openAddTag}
        items={data.listTags}
        onCancel={() => setOpenAddTag(false)}
        onOk={onAddTag}
        allowNoInput
        queryDetails={QUERYTAG}
        queryName="listValuePerTag"
      />
      <DialogAdd
        label1="Software"
        label2="Version"
        open={openAddSoftware}
        items={data.listSoftware}
        onCancel={() => setOpenAddSoftware(false)}
        onOk={onAddSoftware}
        queryDetails={QUERYVERSION}
        queryName="listVersionPerSoftware"
      />
      <DialogAdd
        label1="Component"
        label2="Serial"
        open={openAddComponent}
        items={data.listComponents}
        onCancel={() => setOpenAddComponent(false)}
        onOk={onAddComponent}
        queryDetails={QUERYSERIAL}
        queryName="listSerialPerComponent"
      />
    </>
  );
};

export default RobotMultiple;

// vim: set expandtab shiftwidth=2:
