import {forwardRef} from 'react';
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {TransitionGroup} from 'react-transition-group';
import {stateColor} from '../utils/color';
import stateDecoder from '../utils/stateDecoder';

const SummaryCard = forwardRef((props, ref) => {
  const {states, children} = props;
  const contents = Object.entries(states).map(i => {
    const key = i[0], value = i[1];
    return (
      <Collapse key={key}>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box
                sx={{
                  width: 20,
                  height: 6,
                  backgroundColor: stateColor(key),
                  borderRadius: '3px'
                }}
              />
              <Box fontSize={14}>{stateDecoder(key)}</Box>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" align="right">{value}</Typography>
          </Grid>
        </Grid>
      </Collapse>
    );
  });
  return (
    <Card ref={ref}>
      <CardContent>
        <Box fontSize={16}>
          {children}
        </Box>
        <TransitionGroup>
          {contents}
        </TransitionGroup>
      </CardContent>
    </Card>
  );
});

export default SummaryCard;
