import {Grid, Stack} from '@mui/material';
import RobotCard from './RobotCard';
import RobotCardsBar from './RobotCardsBar';
import {makeComparator} from '../utils/comparator';

const RobotCards = ({
  robots,
  onClick,
  sortModel, onSortModelChange,
  searchKeyword, onSearchKeywordChange,
  searchCategory, onSearchCategoryChange,
  multiMode, onChangeMultiMode, multiRobots,
  onEditMulti,
}) => {
  const robot_ = [...robots];
  robot_.sort(makeComparator(sortModel));
  const cards = robot_.map(i =>
    <RobotCard
      robot={i}
      onClick={onClick}
      key={i.id}
      selected={multiMode && multiRobots.includes(i.id)}
    />
  );
  return (
    <Stack sx={{
      mt: 1,
      width: 'calc(100% - 310px)',
    }}>
      <RobotCardsBar
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        searchKeyword={searchKeyword}
        onSearchKeywordChange={onSearchKeywordChange}
        searchCategory={searchCategory}
        onSearchCategoryChange={onSearchCategoryChange}
        multiMode={multiMode}
        onChangeMultiMode={onChangeMultiMode}
        editDisabled={multiRobots.length === 0}
        onEditMulti={onEditMulti}
      />
      <Grid
        container
        spacing={1}
        sx={{
          mt: 1,
          maxHeight: 'calc(100vh - 130px)',
          overflow: 'scroll'
        }}
      >
        {cards}
      </Grid>
    </Stack>
  );
};

export default RobotCards;
