import {useState} from 'react';
import RobotDetail from './RobotDetail';
import RobotTable from './RobotTable';
import RobotMultiple from './RobotMultiple';
import KarteBar from './KarteBar';
import { SearchFilterCategory } from '../utils/filter';

const MainContents = () => {
  const [showTable, setShowTable] = useState(true);
  const [robotIds, setRobotIds] = useState([]);
  const [filterModel, setFilterModel] = useState({});
  const [sortModel, setSortModel] = useState({field: 'name', order: 'asc'});
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchCategory, setSearchCategory] = useState(SearchFilterCategory.All);
  const [multiMode, setMultiMode] = useState(false);
  const onRobotClick = robotId => {
    if (multiMode) {
      setRobotIds(
        robotIds.includes(robotId) ? robotIds.filter(i => i !== robotId)
                                         : [...robotIds, robotId]
      );
    } else {
      setRobotIds([robotId]);
      setShowTable(false);
    }
  };
  const onChangeMultiMode = enable => {
    if (!enable)
      setRobotIds([]);
    setMultiMode(enable);
  };
  const onEditMulti = () => setShowTable(false);
  const onBackClick = () => {
    if (!multiMode)
      setRobotIds([]);
    setShowTable(true);
  };
  const contents = showTable ? <RobotTable
                                 onClick={onRobotClick}
                                 filterModel={filterModel}
                                 onFilterModelChange={setFilterModel}
                                 sortModel={sortModel}
                                 onSortModelChange={setSortModel}
                                 searchKeyword={searchKeyword}
                                 onSearchKeywordChange={setSearchKeyword}
                                 searchCategory={searchCategory}
                                 onSearchCategoryChange={setSearchCategory}
                                 multiMode={multiMode}
                                 onChangeMultiMode={onChangeMultiMode}
                                 multiRobots={robotIds}
                                 onEditMulti={onEditMulti}
                               />
      : robotIds.length > 1  ? <RobotMultiple ids={robotIds} onClick={onBackClick} />
                             : <RobotDetail id={robotIds[0]} onClick={onBackClick} />;
  return (
    <>
      <KarteBar />
      {contents}
    </>
  );
};

export default MainContents;

// vim: set expandtab shiftwidth=2:
