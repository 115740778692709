import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client';
import App from './components/App';

const client = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND_API_URL || 'https://djwlxxq6bg.execute-api.us-east-1.amazonaws.com/dev/graphql',
  headers: {Authorization: 'Bearer wRMHir29qcJoav1Yu6Twldf4DmJ8jIwMtIwWp67WpXeq'},
  cache: new InMemoryCache()
});

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// vim: set expandtab shiftwidth=2:
