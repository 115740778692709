import {useEffect, useState} from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import SlideDialog from './SlideDialog';

const DialogDefine = ({open, text, label, onCancel, onOk}) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    if (open)
      setValue('');
  }, [open]);
  const error = value.length === 0;
  return (
    <SlideDialog open={open} onClose={onCancel}>
      <DialogTitle>Define {text}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To define {text}, Please enter name here.
        </DialogContentText>
        <TextField
          margin="dense"
          label={label}
          value={value}
          fullWidth
          onChange={e => setValue(e.target.value)}
          error={error}
          helperText={error ? 'Empty' : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button disabled={error} onClick={() => onOk(value)}>Define</Button>
      </DialogActions>
    </SlideDialog>
  );
};

export default DialogDefine;

// vim: set expandtab shiftwidth=2:
