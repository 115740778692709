export const ignoreCaseComparator = (a, b) => {
  if (!a)
    return -1;
  if (!b)
    return 1;
  const A = a.toUpperCase();
  const B = b.toUpperCase();
  return A < B ? -1
       : A > B ?  1
               :  0;
};

export const makeComparator = sortModel => {
  const comparator =
    sortModel.field === 'name'     ? (a, b) => ignoreCaseComparator(a.history?.[0].name, b.history?.[0].name)
  : sortModel.field === 'status'   ? (a, b) => a.history?.[0].state - b.history?.[0].state
  : sortModel.field === 'location' ? (a, b) => ignoreCaseComparator(a.history?.[0].location, b.history?.[0].location)
                                   : (a, b) =>
      {
        const A = a.history?.[0].created_at;
        const B = b.history?.[0].created_at;
        return A < B ? -1 : A > B ? 1 : 0;
      };
  return sortModel.order === 'asc' ? comparator : (a, b) => -comparator(a, b);
};
