import {useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import {Modal} from '@mui/material';

const QUERY = gql`
  query S3DownloadUrl($s3Key: String!) {
    s3DownloadUrl(s3Key: $s3Key)
  }
`;

const S3File = ({file_s3_key}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {loading, error, data} = useQuery(QUERY, {
    variables: {s3Key: file_s3_key},
  });
  const isImage =
    file_s3_key.endsWith('.png') ||
    file_s3_key.endsWith('.jpg') ||
    file_s3_key.endsWith('.jpeg') ||
    file_s3_key.endsWith('.gif') ||
    file_s3_key.endsWith('.bmp') ||
    file_s3_key.endsWith('.tiff');
  if (loading || !data?.s3DownloadUrl)
    return <span>Loading...</span>;
  else if (error)
    return <span>Error retrieving the file {file_s3_key}</span>;
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  return (
    <span>
      {
        !isImage ? <a href={data.s3DownloadUrl} download>file download</a>
                 :
          <span>
            <button onClick={handleModalOpen}>
              <img src={data.s3DownloadUrl} alt={'s3 file'} height={'100px'} />
            </button>
            <Modal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              sx={{
                maxWidth: "80%",
                maxHeight: "80%"
              }}
            >
              <img src={data.s3DownloadUrl} alt={'s3 file'} width={'80%'} />
            </Modal>
          </span>
      }
    </span>
  );
};

export default S3File;
