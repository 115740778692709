import {forwardRef} from 'react';
import {Dialog, Slide} from '@mui/material';

const Transition = forwardRef((props, ref) => {
  return <Slide direction="left" ref={ref} {...props} />;
});

const SlideDialog = props => (
  <Dialog {...props} TransitionComponent={Transition}>
    {props.children}
  </Dialog>
);

export default SlideDialog;
