import {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
} from '@mui/material';
import SlideDialog from './SlideDialog';

const DialogAdd = ({label1, label2, open, items, onCancel, onOk, allowNoInput, queryDetails, queryName}) => {
  const [id, setId] = useState('');
  const [value, setValue] = useState('');
  const [query, {data}] = useLazyQuery(queryDetails);
  useEffect(() => {
    if (open) {
      setId('');
      setValue('');
    }
  }, [open]);
  const errorId = id.length === 0;
  const errorValue = !allowNoInput && value.length === 0;
  const contents = items?.map(i =>
    <MenuItem key={i.id} value={i.id}>{i.description}</MenuItem>
  );
  const text1 = label1.toLowerCase();
  const text2 = label2.toLowerCase();
  const label2_ = allowNoInput ? `${label2} (Optional)` : label2;
  return (
    <SlideDialog open={open} onClose={onCancel}>
      <DialogTitle>Add {text1}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To add {text1}, Please select {text1} and enter {text2} here.
        </DialogContentText>
        <FormControl margin="normal">
          <InputLabel>{label1}</InputLabel>
          <Select
            label={label1}
            sx={{width:400}}
            value={id}
            onChange={e => {
              if (!allowNoInput) {
                query({variables: {id: e.target.value}});
              }
              setId(e.target.value);
            }}
            error={errorId}
          >
            {contents}
          </Select>
          <FormHelperText error={errorId}>
            {errorId ? `Select ${text1}` : ''}
          </FormHelperText>
          {allowNoInput ? <TextField
            fullWidth
            margin="normal"
            label={label2_}
            value={value}
            onChange={e => setValue(e.target.value)}
            placeholder={label2_}
            error={errorValue}
            helperText={errorValue ? 'Empty' : ''}
          /> :
          <Autocomplete
            freeSolo
            options={data ? data[queryName] : []}
            onChange={(_,selectValue) => setValue(selectValue)}
            renderInput={(params) => <TextField {...params}
              label={label2_}
              value={value}
              onChange={e => setValue(e.target.value)}
              placeholder={label2_}
              error={errorValue}
              helperText={errorValue ? 'Empty' : ''}
            />}
          />}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button disabled={errorId || errorValue} onClick={() => onOk(id, value)}>Add</Button>
      </DialogActions>
    </SlideDialog>
  );
};

export default DialogAdd;

// vim: set expandtab shiftwidth=2:
