import {Route, Routes} from 'react-router-dom';
import {withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import AuthContext from './AuthContext';
import MainContents from './MainContents';
import RobotTimeline from './RobotTimeline';

const App = ({signOut, user}) =>
  <div className="App">
    <AuthContext>
      <Routes>
        <Route path="/" element={<MainContents />} />
        <Route path="/index.html" element={<MainContents />} />
        <Route path="/timeline" element={<RobotTimeline />} />
      </Routes>
    </AuthContext>
  </div>;

export default withAuthenticator(App, {hideSignUp: true, socialProviders: ['google']});

// vim: set expandtab shiftwidth=2:
