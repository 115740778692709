import {Button, Grid} from '@mui/material';

const ButtonsAddDefine = ({text, onAdd, onDefine}) =>
  <Grid container spacing={2} sx={{mt:2}}>
    <Grid item>
      <Button
        onClick={onAdd}
        variant="contained"
        size="large"
      >
        Add {text} ...
      </Button>
    </Grid>
    <Grid item>
      <Button
        onClick={onDefine}
        variant="contained"
        size="large"
      >
        Define {text} ...
      </Button>
    </Grid>
  </Grid>;

export default ButtonsAddDefine;

// vim: set expandtab shiftwidth=2:
