const pad = (width, value) => String(value).padStart(width, '0');

const toJSTString = datetime => {
  let date = new Date(datetime);
  if (Number.isNaN(date.getDate())) {
    // yyyy-mm-dd HH:MM:SS.ssssss+ZZ -> yyyy-mm-ddTHH:MM:SS+ZZZZ
    const [yyyymmddHHMMSS, ssssssZZ] = datetime.split('.');
    const [, ZZ] = ssssssZZ.split('+');
    const newdatetime = `${yyyymmddHHMMSS.replace(' ', 'T')}+${pad(4, ZZ)}`;
    date = new Date(newdatetime);
  }
  const yyyy = date.getFullYear();
  const mm   = pad(2, date.getMonth() + 1);
  const dd   = pad(2, date.getDate());
  const HH   = pad(2, date.getHours());
  const MM   = pad(2, date.getMinutes());
  return `${yyyy}/${mm}/${dd} ${HH}:${MM}`;
};

export default toJSTString;

// vim: set expandtab shiftwidth=2:
