import {useState} from 'react';
import {
  Button,
  Divider,
  Grid,
  Tab,
  Tabs
} from '@mui/material';
import RobotDetailComponent from './RobotDetailComponent';
import RobotDetailHeader from './RobotDetailHeader';
import RobotDetailHistory from './RobotDetailHistory';
import RobotDetailSoftware from './RobotDetailSoftware';
import RobotDetailTag from './RobotDetailTag';

const STATE = {
  history: 0,
  software: 1,
  component: 2,
  tag: 3,
};

const RobotDetail = ({id, onClick}) => {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [state, setState] = useState(STATE.history);
  const onChange = (e, state) => {
    setState(state);
  };
  const contents =
    state === STATE.history   ? <RobotDetailHistory id={id} isFileUploading={isFileUploading} />
  : state === STATE.software  ? <RobotDetailSoftware id={id} />
  : state === STATE.component ? <RobotDetailComponent id={id} />
                              : <RobotDetailTag id={id} />;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={{mt:1}}>
        <Button size="large" onClick={onClick}>Back to list</Button>
      </Grid>
      <Grid item xs={12}><Divider /></Grid>
      <Grid item xs={12}><RobotDetailHeader id={id} setIsFileUploading={setIsFileUploading} /></Grid>
      <Grid item xs={12}><Divider /></Grid>
      <Grid item xs={12}>
        <Tabs value={state} onChange={onChange}>
          <Tab label="History" />
          <Tab label="Software" />
          <Tab label="Components" />
          <Tab label="Tag" />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {contents}
      </Grid>
    </Grid>
  );
};

export default RobotDetail;

// vim: set expandtab shiftwidth=2:
