const labels = [
  '',
  'Memo',
  'Hardware modification',
  'Software modification',
  'Electronics modification',
  'Unknown',
];

const labelsShort = [
  '',
  'Memo',
  'H/W',
  'S/W',
  'ELE',
  'UNK',
];

export const categoryDecoder = number => {
  if (number >= labels.length)
    number = labels.length - 1;
  return labels[number];
};

export const categoryDecoderShort = number => {
  if (number >= labelsShort.length)
    number = labelsShort.length - 1;
  return labelsShort[number];
};
