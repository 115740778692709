import {useState} from 'react';
import {useMutation, gql} from '@apollo/client';
import dayjs from 'dayjs';
import {
  AppBar,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  IconButton,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import Timeline, {
  CursorMarker,
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css'
import {useAuth} from './AuthContext';
import BoxNoWrap from './BoxNoWrap';
import RobotCardsBar from './RobotCardsBar';
import SlideDialog from './SlideDialog';
import {
  locationColor,
  stateColor,
  semiTransparentColor,
  defaultBackgroundColor
} from '../utils/color';
import {ignoreCaseComparator, makeComparator} from '../utils/comparator';
import {postSlack} from '../utils/slack';
import '../styles/RobotTimeline.css';
import {dateTimeFormat} from '../utils/dateTime'

const SET = gql`
  mutation Mutation($operator: String!, $id: ID!, $events: [EventInput!]!) {
    setEvents(operator: $operator, id: $id, events: $events) {
      id
      serial_number
      event {
        name
        start_date
        end_date
      }
      history {
          name
      }
    }
  }
`;

const SETMULTIPLE = gql`
  mutation MutationMultiple($operator: String!, $ids: [ID!]!, $events: [EventInput!]!) {
    setEventsMultiple(operator: $operator, ids: $ids, events: $events) {
      id
      serial_number
      event {
        name
        start_date
        end_date
      }
      history {
        name
      }
    }
  }
`;

const primaryLabelFormatter = ([start, end], unit, label) => {
  switch (unit) {
  case 'year' : return start.format('YYYY');
  case 'month': return start.format('YYYY/M');
  case 'day'  : return start.format('YYYY/M/D');
  case 'hour' : return start.format('YYYY/M/D HH:00');
  default     : return '';
  }
};

const labelFormatter = ([start, end], unit, label) => {
  switch (unit) {
  case 'year'  : return 'YYYY';
  case 'month' : return start.format(label < 50 ? 'M' : label < 100 ? 'YY/M' : 'YYYY/M');
  case 'day'   : return start.format(label < 50 ? 'D' : label < 100 ? 'M/D' : 'YYYY/M/D');
  case 'hour'  : return start.format(label < 50 ? 'HH' : label < 100 ? 'HH:00' : 'M/D HH:00');
  case 'minute': return start.format('mm');
  case 'second': return start.format('ss');
  default:       return '';
  }
};

const RobotTimelineDetail = ({
  robots,
  locations,
  sortModel, onSortModelChange,
  searchKeyword, onSearchKeywordChange,
  onTimelineUpdate, setIsUpdateEvent,
  searchCategory, onSearchCategoryChange,
  onRobotClick,
}) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [events, setEvents] = useState(null);
  const [multiMode, setMultiMode] = useState(false);
  const [selectIds, setSelectIds] = useState([]);
  const [isDoubleClick, setIsDoubleClick] = useState(false);
  const [isInputText, setIsInputText] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [newLocation, setNewLocation] = useState({'name': null, 'id': null});
  const [openAlert, setOpenAlert] = useState(false);
  const [draggedItem, setDraggedItem] = useState();
  const {getEmail} = useAuth();
  const [updateEvents] = useMutation(SET, {
    onCompleted: ({setEvents: robot}) => {
      const target = `${robot?.history?.[0].name ?? ''} / ${robot?.serial_number}`;
      const contents = robot?.event?.map(i =>
        `${i.name}: ${i.start_date} - ${i.end_date ?? ''}`
      ).join('\n');
      postSlack(getEmail(), `${target}: set event\n${contents}`);
      setDraggedItem(undefined);
    }
  });
  const [updateEventsMultiple] = useMutation(SETMULTIPLE, {
    onCompleted: ({setEventsMultiple: robots}) => {
      const targets = robots?.map(i => `${i?.history?.[0].name ?? ''} / ${i?.serial_number}`).join('\n');
      const contents = robots?.[0]?.event?.map(i =>
        `${i.name}: ${i.start_date} - ${i.end_date ?? ''}`
      ).join('\n');
      postSlack(getEmail(), `set event\n${targets}\n${contents}`);
    }
  });
  const eventLocationSet = robots.reduce((result, i) => i.event?.reduce((result, i) => result.add(i.name), result), new Set());
  const eventLocations = Array.from(eventLocationSet.values()).sort(ignoreCaseComparator).filter(i => !!i && !locations.includes(i));
  const robots_ = [...robots];
  robots_.sort(makeComparator(sortModel));
  const onChangeMultiMode = enable => {
    setSelectIds([]);
    setMultiMode(enable);
  };
  const openDialog = (name, events) => {
    setName(name);
    setEvents(events);
    setOpen(true);
  };
  const openDialogSingle = id => {
    const robot = robots_.find(i => i.id === id);
    const events = robot?.event?.map(i => ({
      select: locations.includes(i.name) || eventLocations.includes(i.name) ? i.name : '',
      name: i.name,
      start_date: i.start_date,
      end_date: i.end_date
    }));
    if (!multiMode)
      setSelectIds([id]);
    openDialog(robot?.history?.[0].name, events);
  };
  const onEditMulti = () => {
    if (selectIds.length === 1) {
      openDialogSingle(selectIds[0]);
    } else {
      const selectRobots = selectIds.map(id => robots_.find(i => id === i.id));
      const events = selectRobots.flatMap(i => i?.event?.map(i => ({
        select: locations.includes(i.name) || eventLocations.includes(i.name) ? i.name : '',
        name: i.name,
        start_date: i.start_date,
        end_date: i.end_date
      }))).reduce((result, i) => {
        if (!result.find(j =>
          i.name === j.name &&
          i.start_date === j.start_date &&
          i.end_date === j.end_date))
          result.push(i);
        return result;
      }, []).sort((a, b) =>
        a.start_date < b.start_date ? -1 : a.start_date > b.start_date ? 1 : 0
      );
      const name = selectRobots.map(i => i.history?.[0].name).join(' / ');
      openDialog(name, events);
    }
  };
  const onClick = id => {
    if (multiMode) {
      setSelectIds(selectIds.includes(id)
        ? selectIds.filter(i => i !== id)
        : [...selectIds, id]);
    } else {
      onRobotClick(id);
    }
  };
  const onItemClick = _ => {
    setIsDoubleClick(true);
  };
  const convertEventBackend = (originalEvent, changedId, newName, newStartDate, newEndDate) => {
    return originalEvent.map(i => {
      if (i.id === changedId) {
        return {
          name: newName ?? i.name,
          start_date: newStartDate ?? i.start_date,
          end_date: newEndDate ?? i.end_date
        };
      }
      return {
        name: i.name,
        start_date: i.start_date,
        end_date: i.end_date
      };
    });
  }
  const handleItemMove = (itemId, dragTime, _) => {
    setIsUpdateEvent(true);
    const robotMove = robots_.find(i => i.event?.find(i => i.id === itemId));
    const eventMove = robotMove.event.find(i => i.id === itemId);
    const newStartDate = dayjs(dragTime).format(dateTimeFormat);
    const newEndDate = eventMove.end_date ? 
      dayjs(dragTime + dayjs(eventMove.end_date, dateTimeFormat).diff(dayjs(eventMove.start_date, dateTimeFormat))).format(dateTimeFormat) : 
      eventMove.end_date;
    updateEvents({variables: {
      operator: getEmail(), 
      id: robotMove.id, 
      events: convertEventBackend(robotMove.event, eventMove.id, eventMove.name, newStartDate, newEndDate)
    }});
  };
  const handleItemResize = (itemId, time, edge) => {
    setIsUpdateEvent(true);
    const robotMove = robots_.find(i => i.event?.find(i => i.id === itemId));
    const eventMove = robotMove.event.find(i => i.id === itemId);
    const newDate = dayjs(time).format(dateTimeFormat);
    if (edge==='left' && (eventMove.end_date === null || dayjs(time).isBefore(eventMove.end_date))){
      updateEvents({variables: {
        operator: getEmail(), 
        id: robotMove.id, 
        events: convertEventBackend(robotMove.event, eventMove.id, eventMove.name, newDate, eventMove.end_date)
      }});
    } else if (edge==='right' && (eventMove.start_date === null || dayjs(time).isAfter(eventMove.start_date))) {
      updateEvents({variables: {
        operator: getEmail(), 
        id: robotMove.id, 
        events: convertEventBackend(robotMove.event, eventMove.id, eventMove.name, eventMove.start_date, newDate)
      }});
    } else {
      setIsUpdateEvent(false);
      setDraggedItem(undefined);
    }
  };
  const onSelectChange = (index, value) => {
    const newEvents = [...events];
    newEvents[index].select = value;
    newEvents[index].name = value;
    setEvents(newEvents);
  };
  const onSelectChangeId = (id, value) => {
    if (value === '') {
      setIsInputText(true);
    } else {
      const newEvents = events.map(i => {
        if (i.id === id) {
          return {
            "name": value,
            "id": i.id,
            "end_date": i.end_date,
            "start_date": i.start_date
          }
        }
        return i;
      });
      setEvents(newEvents);
      setNewLocation({'name': value, 'id': id});
    }
  }
  const onInputChange = (value) => {
    setNewLocation({'name': value, 'id': newLocation.id});
  }
  const onItemSelect = (itemId, _1, _2) => {
    const selectedRobot = robots_.find(i => i.event?.find(i => i.id === itemId));
    setEvents(selectedRobot?.event);
    setSelectIds([selectedRobot?.id]);
    setCurrentLocation(selectedRobot?.event.find(i => i.id === itemId)?.name);
    setNewLocation({'name': null, 'id': itemId});
  }
  const resetState = () => {
    setIsDoubleClick(false);
    setEvents(null);
    setCurrentLocation(null);
    setNewLocation({'name': null, 'id': null});
    setSelectIds([]);
  }
  const onItemDeselect = e =>{
    if (newLocation.name && newLocation.name !== currentLocation) {
      setOpenAlert(true);
    } else {
      resetState();
    }
  }
  const handleClickNo = () => {
    setOpenAlert(false);
    resetState();
  }
  const handleClickYes = () => {
    updateEvents({variables: {operator: getEmail(), id: selectIds[0], events: convertEventBackend(events)}});
    setOpenAlert(false);
    resetState();
    onTimelineUpdate();
  }
  const handleInputNo = () => {
    setIsInputText(false);
    resetState();
  }
  const handleInputYes = () => {
    updateEvents({variables: {operator: getEmail(), id: selectIds[0], events: convertEventBackend(events, newLocation.id, newLocation.name)}});
    setIsInputText(false);
    resetState();
  }
  const handleCanvasClick = (groupId, _1, _2) => {
    openDialogSingle(groupId);
  }
  const handleItemDrag = (eventType, _1, _2, _3, _4) => {
    setDraggedItem({time: dayjs(eventType.time).format(dateTimeFormat)});
  }
  const onAddEvent = () => {
    const newEvents = [...events];
    newEvents.push({
      select: '', name: '', start_date: dayjs().format(dateTimeFormat)
    });
    setEvents(newEvents);
  };
  const onRemoveEvent = index => {
    setEvents(events.filter((e, i) => i !== index));
  };
  const onChangeName = (index, value) => {
    const newEvents = [...events];
    newEvents[index].name = value;
    setEvents(newEvents);
  };
  const onChangeStart = (index, date) => {
    if (date?.isValid()) {
      const newEvents = [...events];
      newEvents[index].start_date = date.format(dateTimeFormat);
      setEvents(newEvents);
    }
  };
  const onChangeEnd = (index, date) => {
    if (date) {
      if (date.isValid()) {
        const newEvents = [...events];
        newEvents[index].end_date = date.format(dateTimeFormat);
        setEvents(newEvents);
      }
    } else {
      const newEvents = [...events];
      delete newEvents[index].end_date;
      setEvents(newEvents);
    }
  };
  const onCancel = () => {
    if (!multiMode)
      setSelectIds([]);
    setOpen(false);
  };
  const onOk = () => {
    const eventsToBackend = convertEventBackend(events);
    if (multiMode) {
      updateEventsMultiple({variables: {operator: getEmail(), ids: selectIds, events: eventsToBackend}});
    } else {
      updateEvents({variables: {operator: getEmail(), id: selectIds[0], events: eventsToBackend}});
      setSelectIds([]);
    }
    setName('');
    setEvents(null);
    setOpen(false);
    onTimelineUpdate();
  };
  const error =
    events &&
    (events.length === 0 || events.reduce((result, i) =>
      result ||
      i.name.length === 0 ||
      (i.end_date && dayjs(i.start_date).isAfter(dayjs(i.end_date))) , false));
  const menuItems = [
    <MenuItem key="" value=""><em>Input or select location</em></MenuItem>,
    <ListSubheader key=" header 1 ">Locations (from history)</ListSubheader>,
    ...locations.map(i => <MenuItem key={i} value={i}>{i}</MenuItem>),
    <ListSubheader key=" header 2 ">Locations (from timeline event)</ListSubheader>,
    ...eventLocations.map(i => <MenuItem key={i} value={i}>{i}</MenuItem>)
  ];
  const rows = events?.map((e, i) =>
    <TableRow key={i}>
      <TableCell>
        <FormControl>
          <Select
            sx={{width: '250px'}}
            value={e.select}
            onChange={e => onSelectChange(i, e.target.value)}
            displayEmpty
          >
            {menuItems}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        {
          e.select === ''
            ? <TextField
                value={e.name}
                onChange={e => onChangeName(i, e.target.value)}
              />
            : ''
        }
      </TableCell>
      <TableCell>
        <DesktopDatePicker
          label="Start"
          inputFormat={dateTimeFormat}
          onChange={date => onChangeStart(i, date)}
          value={dayjs(e.start_date)}
          renderInput={params => <TextField {...params} />}
        />
      </TableCell>
      <TableCell>
        <DesktopDatePicker
          label="End"
          inputFormat={dateTimeFormat}
          onChange={date => onChangeEnd(i, date)}
          value={e.end_date ? dayjs(e.end_date) : null}
          renderInput={params => <TextField {...params} />}
        />
      </TableCell>
      <TableCell>
        <IconButton color="inherit" onClick={() => onRemoveEvent(i)}><CloseIcon /></IconButton>
      </TableCell>
    </TableRow>
  );
  const now = dayjs();
  const groups = robots_.map(i => {
    const location = i.history?.[0].location;
    const locationError = i.event?.reduce((result, i) => {
      if (!result) {
        const todayIsInEvent =
          (i.end_date &&
            now.isAfter(dayjs(i.start_date)) &&
            now.isBefore(dayjs(i.end_date))) ||
          (!i.end_date &&
            now.isAfter(dayjs(i.start_date)));
        result = todayIsInEvent && location !== i.name;
      }
      return result;
    }, false);
    return {
      id: i.id,
      title: i.history?.[0].name,
      location: location,
      state: i.history?.[0].state,
      error: locationError
    };
  });
  const items = robots_.flatMap(robot => {
    const halfYearLater = dayjs().add(6, 'month');
    return robot.event?.map(e => {
      const item = {
        id: e.id,
        group: robot.id,
        title: e.name,
        start_time: dayjs(e.start_date),
        canMove: true,
        canResize: "both",
      };
      item.end_time = e.end_date ? dayjs(e.end_date).add(1, 'day')
                                 : halfYearLater;
      return item;
    });
  });
  return (
    <div style={{width: 'calc(100% - 310px)'}}>
      <Dialog
        open={openAlert}
        onClose={handleClickNo}
      >
        <DialogTitle>
          Change Location from {currentLocation} to {newLocation.name} ?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClickYes}>YES</Button>
          <Button onClick={handleClickNo}>NO</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isInputText} onClose={handleInputNo}>
      <DialogTitle>Change Location from {currentLocation} to:</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          value={newLocation?.name ?? ""}
          onChange={e => onInputChange(e.target.value)}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleInputYes}>YES</Button>
        <Button onClick={handleInputNo}>NO</Button>
      </DialogActions>
      </Dialog>
      <Stack sx={{mt: 1}}>
        <RobotCardsBar
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          searchKeyword={searchKeyword}
          onSearchKeywordChange={onSearchKeywordChange}
          searchCategory={searchCategory}
          onSearchCategoryChange={onSearchCategoryChange}
          multiMode={multiMode}
          onChangeMultiMode={onChangeMultiMode}
          editDisabled={selectIds.length === 0}
          onEditMulti={onEditMulti}
          timelineMode
        />
        <Card sx={{
          mt: 1,
          maxHeight: 'calc(100vh - 130px)',
          overflow: 'scroll'
        }}>
          <Timeline
            minZoom={86400 * 30 * 1000}
            maxZoom={86400 * 365 * 1000}
            groups={groups}
            items={items}
            lineHeight={40}
            itemHeightRatio={0.75}
            sidebarWidth={250}
            defaultTimeStart={now.subtract(1, 'week')}
            defaultTimeEnd={now.add(6, 'month')}
            canChangeGroup={false}
            onItemMove={handleItemMove}
            onItemResize={handleItemResize}
            onItemClick={onItemClick}
            onItemDeselect={onItemDeselect}
            onItemSelect={onItemSelect}
            onCanvasDoubleClick={handleCanvasClick}
            onItemDrag={handleItemDrag}
            groupRenderer={({group}) => {
              const color = multiMode && selectIds.find(i => i === group.id)
                ? '#e0ffe0'
                : 'transparent';
              return (
                <Stack
                  direction="row"
                  alignItems="center"
                  onClick={() => onClick(group.id)}
                  style={{fontSize: '14px', background: color}}
                >
                  <Box
                    sx={{
                      width: 4,
                      height: 20,
                      backgroundColor: stateColor(group.state),
                      borderRadius: '2px'
                    }}
                  />
                  <span style={{flexGrow: 1, marginLeft: '5px'}}>
                    {group.title}
                  </span>
                  <Box
                    sx={{
                      mx: 1,
                      width: 20,
                      height: 6,
                      backgroundColor: locationColor(group.location),
                      borderRadius: '3px'
                    }}
                  />
                  <BoxNoWrap sx={{fontSize: 14, marginRight: '5px'}}>{group.location}</BoxNoWrap>
                  {
                    group.error &&
                    <Box
                      sx={{
                        mr: 0.5,
                        width: 6,
                        height: 6,
                        backgroundColor: '#c02010',
                        borderRadius: '3px'
                      }}
                    />
                  }
                </Stack>
              );
            }}
            itemRenderer={({item, itemContext, getItemProps}) => {
              const width = itemContext.width - 20;
              const props = getItemProps(item.itemProps);
              props.style = {
                ...props.style,
                background: itemContext.selected ? defaultBackgroundColor : '#fff',
                color: '#000',
                border: '2px solid #eee',
                borderRadius: '6px',
                boxShadow: '4px 4px 10px 0 rgba(0, 0, 0, 0.3)',
                lineHeight: '25px',
                fontSize: '12px',
              };
              const color = locationColor(locations.find(i => i === item.title)) ?? '#ddd';
              return (
                <div {...props}>
                  <div
                    className="rct-item-content"
                    style={{maxHeight: `${itemContext.dimensions.height}`, padding: '0 0'}}
                  >
                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Add" placement="top">
                      <Box
                        sx={{
                          width: 6,
                          height: 30,
                          background: color
                        }}
                      />
                      </Tooltip>
                      {
                        itemContext.selected && isDoubleClick ?
                        <Select
                          sx={{width: '200px', height: '25px', bgcolor: defaultBackgroundColor}}
                          value={events?.find(i => i.id === item.id)?.name ?? itemContext.title}
                          onChange={e => onSelectChangeId(item.id, e.target.value)}
                          displayEmpty
                        >
                          {menuItems}
                        </Select> :
                        width > 0 && <BoxNoWrap
                          sx={{
                            width: width,
                            fontSize: 12,
                          }}
                        >
                          {itemContext.title}
                        </BoxNoWrap>
                      }
                    </Stack>
                  </div>
                </div>
              );
            }}
          >
            <TimelineHeaders style={{position: 'sticky', top: 0, zIndex: 100}}>
              <SidebarHeader>
                {({getRootProps}) => {
                  const props = getRootProps();
                  props.style = {...props.style, alignItems: 'flex-end'};
                  return (
                    <Stack direction="row" {...props}>
                      <span style={{flexGrow: 1, marginLeft: '10px'}}>Name</span>
                      <span style={{marginRight: '10px'}}>Current location</span>
                    </Stack>
                  );
                }}
              </SidebarHeader>
              <DateHeader unit="primaryHeader" labelFormat={primaryLabelFormatter} />
              <DateHeader labelFormat={labelFormatter} />
            </TimelineHeaders>
            <TimelineMarkers>
              <TodayMarker>
                {({styles, date}) => <div style={{...styles, backgroundColor: 'red'}}/>}
              </TodayMarker>
              <CursorMarker>
                {({styles, date}) => <div style={{...styles, backgroundColor: 'blue'}}/>}
              </CursorMarker>
            </TimelineMarkers>
          </Timeline>
        </Card>
      </Stack>
      <SlideDialog fullScreen open={open} onClose={onCancel}>
        <AppBar>
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flex: 1,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {name} timeline events
            </Typography>
            <Button color="inherit" onClick={onCancel}>Cancel</Button>
            <Button disabled={error} color="inherit" onClick={onOk}>Ok</Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Target</TableCell>
                  <TableCell>Start</TableCell>
                  <TableCell>End</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {rows}
                </LocalizationProvider>
                <TableRow>
                  <TableCell>
                    <Button onClick={onAddEvent}>Add event</Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </SlideDialog>
      {draggedItem !== undefined ?
      <div
        style={{
          position: "fixed",
          left: 100,
          bottom: 50,
          background: semiTransparentColor,
          color: "white",
          padding: 10,
          fontSize: 20,
          borderRadius: 5,
          zIndex: 85
        }}
      >
        {draggedItem.time}
      </div> : <div></div>}
    </div>
  );
};

export default RobotTimelineDetail;
