import {useState} from 'react';
import {useMutation, useQuery, gql} from '@apollo/client';
import {
  Button,
  Grid,
  Typography
} from '@mui/material';
import {useAuth} from './AuthContext';
import LoadingLine from './LoadingLine';
import DialogLocation from './DialogLocation';
import {getLocations} from '../utils/locations';
import {postSlack} from '../utils/slack';

const QUERYROBOT = gql`
query Query {
  listRobots
}
`;
const QUERY = gql`
query Query($ids: [ID!]!) {
    getListRobots(ids: $ids) {
      id
      serial_number
      history {
        location
      }
    }
  }
`;

const SET = gql`
  mutation Mutation($operator: String!, $id: ID!, $location: String!) {
    setLocation(operator: $operator, id: $id, location: $location) {
      id
      serial_number
      history {
        id
        state
        name
        location
        log
        created_at
      }
    }
  }
`;

const RobotDetailHeaderLocation = ({mode, robot}) => {
  const [open, setOpen] = useState(false);
  const {getEmail} = useAuth();
  const dataId = useQuery(QUERYROBOT);
  const dataRobot1 = useQuery(QUERY, {
    variables: {ids: dataId.data?.listRobots.slice(0,100)},
    skip: dataId.loading || !dataId.data?.listRobots
  })
  const dataRobot2 = useQuery(QUERY, {
    variables: {ids: dataId.data?.listRobots.slice(100,200)},
    skip: dataRobot1.loading || !dataRobot1.data?.getListRobots
  })
  const dataRobot3 = useQuery(QUERY, {
    variables: {ids: dataId.data?.listRobots.slice(200,dataId.data?.listRobots.length)},
    skip: dataRobot2.loading || !dataRobot2.data?.getListRobots
  })
  const mergeRobot = dataRobot1.data?.getListRobots.concat(dataRobot2.data?.getListRobots, dataRobot3.data?.getListRobots);
  const [setLocation] = useMutation(SET, {
    onCompleted: ({setLocation: robot}) => {
      const history = robot?.history?.[0];
      const target = `${history?.name ?? ''} / ${robot?.serial_number}`;
      const contents = `location changed to: ${history?.location}`;
      postSlack(getEmail(), `${target}: ${contents}`);
    }
  });
  if (dataRobot3.loading || dataRobot2.loading || dataRobot1.loading) return <LoadingLine />;
  if (dataRobot3.error || dataRobot2.error || dataRobot1.error) return <p>Error :(</p>;
  const onUpdate = location => {
    setLocation({variables: {operator: getEmail(), id: robot.id, location: location}});
    setOpen(false);
  };
  const locations = getLocations(dataRobot1.data?.getListRobots && dataRobot2.data?.getListRobots && dataRobot3.data?.getListRobots ? mergeRobot : []);
  const location = robot.history?.[0].location ?? '';
  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={2}>
          <Typography variant="body1" align="right">Location:</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body1">
            {location}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Button onClick={() => setOpen(true)}>Update</Button>
        </Grid>
      </Grid>
      <DialogLocation
        open={open}
        onCancel={() => setOpen(false)}
        onOk={onUpdate}
        locations={locations}
        location={location}
      />
    </>
  );
};

export default RobotDetailHeaderLocation;

// vim: set expandtab shiftwidth=2:
