import {
  AppBar,
  Box,
  Button,
  Link,
  Toolbar,
  Typography
} from '@mui/material';
import {Auth} from 'aws-amplify';
import logo from '../logo.png';

const KarteBar = () =>
  <AppBar position="static">
    <Toolbar>
      <Link href="/">
        <Box
          component="img"
          sx={{height: 32, mr: 3}}
          alt="logo"
          src={logo}
        />
      </Link>
      <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
        Robot Karte
      </Typography>
      <Box sx={{flexGrow: 2}}>
        <Button color="inherit" href="/">Card</Button>
        <Button color="inherit" href="/timeline">Timeline</Button>
      </Box>
      <Button color="inherit" onClick={() => Auth.signOut()}>Sign out</Button>
    </Toolbar>
  </AppBar>;

export default KarteBar;
