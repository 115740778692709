const locationMap = {};
const locationColors = [
  '#ffcdd2',
  '#e1bee7',
  '#c5cae9',
  '#b3e5fc',
  '#b2dfdb',
  '#dcedc8',
  '#fff9c4',
  '#ffe0b2',
  '#d7ccc8',
  '#cfd8dc',
];

const stateColors = [
  '#d9d9d9',
  '#b2ff59',
  '#97e0bd',
  '#98e1ff',
  '#e040fb',
  '#27aae1',
  '#ff5252',
  '#ffff00',
  '#820e0e',
  '#bcaaa4',
];

const categoryColors = [
  '#ffffff',
  '#cfd8dc',
  '#b2ff59',
  '#ffff00',
  '#ff5252',
  '#e040fb',
  '#820e0e',
  '#bcaaa4',
  '#27aae1',
  '#97e0bd',
  '#98e1ff',
];

export const locationColor = location => {
  if (!!location && !locationMap[location]) {
    const index = Object.keys(locationMap).length % locationColors.length;
    locationMap[location] = locationColors[index];
  }
  return locationMap[location];
};

export const stateColor = state => {
  const index = state % stateColors.length;
  return stateColors[index];
};

export const productColor = product => '#303030';

export const operatorColor = operator => '#303030';

export const tagColor = tag => '#303030';

export const categoryColor = number => {
  const index = number % categoryColors.length;
  return categoryColors[index];
};

export const defaultBackgroundColor = '#efefef';

export const semiTransparentColor = "rgba(0, 0, 0, 0.5)";