import {useState} from 'react';
import {useQuery, gql} from '@apollo/client';
import KarteBar from './KarteBar';
import LoadingLine from './LoadingLine';
import RobotSummary from './RobotSummary';
import RobotTimelineDetail from './RobotTimelineDetail';
import RobotDetail from './RobotDetail';
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import {
  filterRobot,
  getStatistics,
  getIndividualStates,
  SearchFilterCategory,
} from '../utils/filter';
import {
  locationColor, 
  defaultBackgroundColor, 
  semiTransparentColor
} from '../utils/color';

const QUERYROBOT = gql`
query Query {
  listRobots
}
`;
const QUERY = gql`
query Query($ids: [ID!]!) {
    getListRobots(ids: $ids) {
      id
      history {
        operator_id
        state
        name
        location
      }
      event {
        id
        name
        start_date
        end_date
      }
      tag {
        description
      }
    }
  }
`;

const RobotTimeline = () => {
  const [showTimeline, setShowTimeline] = useState(true);
  const [robotIds, setRobotIds] = useState([]);
  const [filterModel, setFilterModel] = useState({});
  const [sortModel, setSortModel] = useState({field: 'name', order: 'asc'});
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isUpdateEvent, setIsUpdateEvent] = useState(false);
  const [searchCategory, setSearchCategory] = useState(SearchFilterCategory.All);
  const dataId = useQuery(QUERYROBOT);
  const dataRobot1 = useQuery(QUERY, {
    variables: {ids: dataId.data?.listRobots.slice(0,100)},
    skip: dataId.loading || !dataId.data?.listRobots
  })
  const dataRobot2 = useQuery(QUERY, {
    variables: {ids: dataId.data?.listRobots.slice(100,200)},
    skip: dataRobot1.loading || !dataRobot1.data?.getListRobots
  })
  const dataRobot3 = useQuery(QUERY, {
    variables: {ids: dataId.data?.listRobots.slice(200,dataId.data?.listRobots.length)},
    skip: dataRobot2.loading || !dataRobot2.data?.getListRobots,
    onCompleted: () =>{
      setIsUpdateEvent(false);
    }
  })
  const mergeRobot = dataRobot1.data?.getListRobots.concat(dataRobot2.data?.getListRobots, dataRobot3.data?.getListRobots);
  const robotRefetch = () => {
    dataRobot1.refetch();
    dataRobot2.refetch();
    dataRobot3.refetch();
  }
  if (dataRobot3.loading || dataRobot2.loading || dataRobot1.loading) return <LoadingLine />;
  if (dataRobot3.error || dataRobot2.error || dataRobot1.error) return <p>Error :(</p>;
  const robots = dataRobot1.data?.getListRobots && dataRobot2.data?.getListRobots && dataRobot3.data?.getListRobots ? mergeRobot : [];
  const [states, operators, locations, tags] = getStatistics(robots);
  const filteredRobots = filterRobot(robots, filterModel, searchKeyword, searchCategory);
  const filteredStates = getIndividualStates(filteredRobots);
  const onBackClick = () => {
    setShowTimeline(true);
  };
  const onRobotClick = robotId => {
    setRobotIds([robotId]);
    setShowTimeline(false);
  };
  locations.forEach(i => locationColor(i));
  const contents = showTimeline ? <div style={{
    width: '100%',
    backgroundColor: defaultBackgroundColor,
    display: 'flex'
  }}>
    <CircleSpinnerOverlay loading={isUpdateEvent} overlayColor={semiTransparentColor}></CircleSpinnerOverlay>
    <RobotSummary
      filteredStates={filteredStates}
      operators={operators}
      states={states}
      locations={locations}
      tags={tags}
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
    />
    <RobotTimelineDetail
      robots={filteredRobots}
      locations={locations}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      searchKeyword={searchKeyword}
      onSearchKeywordChange={setSearchKeyword}
      searchCategory={searchCategory}
      onSearchCategoryChange={setSearchCategory}
      onTimelineUpdate={() => robotRefetch()}
      setIsUpdateEvent={setIsUpdateEvent}
      onRobotClick={onRobotClick}
    />
  </div> : <RobotDetail id={robotIds[0]} onClick={onBackClick} />;
  return (
    <>
      <KarteBar />
      {contents}
    </>
  );
};

export default RobotTimeline;
