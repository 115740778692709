import {useEffect, useState} from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import SlideDialog from './SlideDialog';

const DialogLocation = ({
  open,
  onCancel, onOk,
  locations,
  location,
}) => {
  const [selectValue, setSelectValue] = useState('');
  const [locationText, setLocationText] = useState('');
  useEffect(() => {
    if (open) {
      setSelectValue(location);
      setLocationText(location);
    }
  }, [open, location]);
  const onSelectChange = location => {
    setSelectValue(location);
    setLocationText(location);
  };
  const items = [
    <MenuItem key="" value=""><em>Input or select location</em></MenuItem>,
    ...locations.map(location => <MenuItem key={location} value={location}>{location}</MenuItem>)
  ];
  const locationTextEmpty = !locationText || locationText.length === 0;
  return (
    <SlideDialog open={open} onClose={onCancel}>
      <DialogTitle>Update location</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To update location, Please input or select here.
        </DialogContentText>
        <FormControl margin="normal">
          <>
            <Select
              sx={{width:250}}
              value={selectValue}
              onChange={e => onSelectChange(e.target.value)}
              displayEmpty
            >
              {items}
            </Select>
            {selectValue !== '' ||
              <TextField
                fullWidth
                margin="normal"
                label="Location"
                value={locationText}
                onChange={e => setLocationText(e.target.value)}
                placeholder="Location"
                error={locationTextEmpty}
                helperText={locationTextEmpty ? 'Empty' : ''}
              />
            }
          </>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button disabled={locationTextEmpty} onClick={() => onOk(locationText)}>Update</Button>
      </DialogActions>
    </SlideDialog>
  );
};

export default DialogLocation;
