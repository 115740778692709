import React, {useContext, useEffect, useState} from 'react';
import Amplify, {Auth, Hub} from 'aws-amplify';
import {getRedirectUrl} from '../utils/localMode';
import {postSlack} from '../utils/slack';

const Context = React.createContext();

const redirectUrl = getRedirectUrl();

Amplify.configure({
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_FzqpVrcCW',
  aws_user_pools_web_client_id: '6j78el5kufv4go42brvj31na1l',
  oauth: {
    domain: 'robotkarte-prototype.auth.us-east-1.amazoncognito.com',
    scope: ['email','profile','openid'],
    redirectSignIn: redirectUrl,
    redirectSignOut: redirectUrl,
    responseType: 'token'
  }
});

const AuthContext = ({children}) => {
  const [session, setSession] = useState(null);
  const [signInNotified, setSignInNotified] = useState(false);
  useEffect(() => {
    const setupState = async () => {
      setSession(await Auth.currentSession());
    };
    const clearState = () => {
      setSession(null);
    };
    Hub.listen('auth', ({payload: {event, data}}) => {
      switch (event) {
      case 'signIn':
      case 'cognitoHostedUI':
        setupState();
        break;
      case 'signOut':
        clearState();
        break;
      case 'signIn_failure':
      case 'cognitoHostedUI_failure':
      default:
        break;
      }
    });
    setupState();
    return () => {
      Hub.remove('auth', null);
    };
  }, []);
  useEffect(() => {
    if (session && !signInNotified) {
      postSlack(session.getIdToken().payload.email,  'start session (login)');
      setSignInNotified(true);
    }
  }, [session, signInNotified]);
  const value = {
    getEmail: () => session?.idToken?.payload?.email || 'unknown',
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useAuth = () => useContext(Context);

export default AuthContext;

// vim: set expandtab shiftwidth=2:
