import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
} from '@mui/material';

const FilterItem = ({color, onClick, selected, children}) =>
  <Stack
    direction="row"
    alignItems="center"
    spacing={1}
    sx={{
      borderRadius: '12px',
      backgroundColor: selected ? '#e0e0e0' : '#ffffff',
      cursor: 'pointer'
    }}
    onClick={() => onClick(children)}
  >
    <Box
      sx={{
        mx: 1,
        width: 20,
        height: 6,
        backgroundColor: color,
        borderRadius: '3px'
      }}
    />
    <Box fontSize={14}>{children}</Box>
  </Stack>;

const FilterCard = ({items, onItemClick, onResetClick, filter, children}) => {
  const contents = items.map(i =>
    <FilterItem
      color={i.color}
      onClick={onItemClick}
      selected={filter?.includes(i.name)}
      key={i.name}
    >
      {i.name}
    </FilterItem>
  );
  return (
    <Card>
      <CardHeader
        titleTypographyProps={{fontSize: 16}}
        title={children}
        action={<Button size="small" onClick={onResetClick}>Reset</Button>}
      />
      <CardContent>
        {contents}
      </CardContent>
    </Card>
  );
};

export default FilterCard;
