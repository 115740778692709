import {
  Badge,
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
} from '@mui/material';
import dayjs from 'dayjs';
import BoxNoWrap from './BoxNoWrap';
import {locationColor, stateColor} from '../utils/color';
import stateDecoder from '../utils/stateDecoder';
import toJSTString from '../utils/toJSTString';

const RobotCard = ({robot, onClick, selected}) => {
  const state = robot.history?.[0].state;
  const location = robot.history?.[0].location;
  const color = locationColor(location);
  const today = dayjs();
  const locationError = robot.event?.reduce((result, i) => {
    if (!result) {
      const todayIsInEvent =
        (i.end_date &&
          today.isAfter(dayjs(i.start_date)) &&
          today.isBefore(dayjs(i.end_date))) ||
        (!i.end_date &&
          today.isAfter(dayjs(i.start_date)));
      result = todayIsInEvent && location !== i.name;
    }
    return result;
  }, false);
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card onClick={() => onClick(robot.id)} sx={{background: selected ? '#e0ffe0' : '#ffffff'}}>
        <CardContent>
          <Grid container>
            <Grid item xs={5}>
              <BoxNoWrap>
                {robot.history?.[0].name || '[No name]'}
              </BoxNoWrap>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-end">
                {
                  color && (
                    <Box
                      sx={{
                        width: 10,
                        height: 6,
                        backgroundColor: color,
                        borderRadius: '3px'
                      }}
                    />
                  )
                }
                <Badge color="error" variant="dot" invisible={!locationError}>
                  <BoxNoWrap>{location}</BoxNoWrap>
                </Badge>
              </Stack>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <BoxNoWrap sx={{fontSize: 8}}>
                {stateDecoder(state)}
              </BoxNoWrap>
            </Grid>
            <Grid item xs={6}>
              <BoxNoWrap sx={{fontSize: 8, textAlign: 'right'}}>
                {robot.serial_number}
              </BoxNoWrap>
            </Grid>
          </Grid>
          <BoxNoWrap sx={{fontSize: 8, mt: 0.5}}>
            {toJSTString(robot.history?.[0].created_at)}
          </BoxNoWrap>
          <BoxNoWrap sx={{fontSize: 12}}>
            {robot.history?.[0].log}
          </BoxNoWrap>
        </CardContent>
        <Box
          sx={{
            width: 400,
            height: 6,
            background: stateColor(state)
          }}
        />
      </Card>
    </Grid>
  );
};

export default RobotCard;
