import {Box} from '@mui/material';

const BoxNoWrap = props =>
  <Box
    {...props}
    sx={{
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontSize: 16,
      ...props.sx,
    }}
  >
    {props.children}
  </Box>;

export default BoxNoWrap;
