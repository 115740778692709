import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import SlideDialog from './SlideDialog';

const DialogOkCancel = ({open, title, content, onCancel, onOk}) =>
  <SlideDialog open={open} onClose={onCancel}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onOk}>Ok</Button>
    </DialogActions>
  </SlideDialog>;

export default DialogOkCancel;

// vim: set expandtab shiftwidth=2:
